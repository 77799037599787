import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export interface ILoginResponse {
  token: string;
  user: IUser;
}

export interface IUser {
  id: number;
  first_name: string;
  email: string;
  status: string;
  permissions: string[];
  groups: string[];
  phone: number[];
  account: any;
}

const regexValidator = (regex: RegExp, key: string): ValidatorFn => (control: AbstractControl): Record<string, any> | null => {
    const isInvalid = !regex.test(control.value);
    const res = {};
    if (isInvalid) {
        res[key] = { value: control.value };
        return res;
    }
    return null;
};

export const PasswordValidator = {
    rules: [
        Validators.required,
        Validators.minLength(8),
        regexValidator(/[A-Z]/, 'capital'),
        regexValidator(/[a-z]/, 'small'),
        regexValidator(/[0-9]/, 'number'),
        regexValidator(/[$@!%*?&]/, 'special'),
    ],
};
